<template>
  <section class="home-page-wrapper">
    <Header :showlinks="true" />

    <MobileMenu />

    <div class="position-relative">
      <section class="faq-section">
        <div class="text-center faq-title">
          <h1 class="font-weight-bold">
            Frequently Asked questions
          </h1>
        </div>

        <div v-for="faq in faqs" :key="faq._id" class="faq-content">
          <div class="mt-1">
            <app-collapse class="app-collapse rounded">
              <app-collapse-item class="rounded green-color shadow-sm" :title="getValueFromSource(faq, 'title')">
               <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="getValueFromSource(faq, 'content')"></div>
              </app-collapse-item>
            </app-collapse>
          </div>
        </div>
      </section>

      <section class="still">
        <div class="grid-col-2 ju">
          <div></div>
          <div class="d-flex  align-items-center">
            <div>
              <div class="mb-2">
                <h1>Still have questions ?</h1>
              </div>
              <div>
                <p>
                  If you cannot find your answers in our FAQ, <br> you can always contact us. We will respond swiftly
                </p>
              </div>
              <div>
                <button class="p-1" @click="toggleChatwoot">
                  Contact Support
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="position-absolute position-bottom-0 guider">
        <img src="@/assets/images/exxtra/support.png" height="765px" alt="exxtra-support">
      </div>
    </div>

    <Footer />
  </section>
</template>

<script>
import Footer from "@/pages/public/footer.vue";
import Header from "@/pages/public/header.vue";
import MobileMenu from "@/pages/public/MobileMenu.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { get } from "lodash";

export default {
  name: "DetailedFAQ",
  components: { AppCollapseItem, AppCollapse, Header, Footer, MobileMenu },
  data() {
    return {
      loading: false,
      searchFilter: '',
      faqs: [],
      currentPage: 1,
      perPage: 2,
      totalRecords: 0,
      rows: 2,

    }
  },

  created() {
    this.fetchAllData();
  },
  methods: {
    toggleChatwoot() {
      window.$chatwoot.toggle();
    },
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: 0,
          page: this.currentPage,
        }

        const request = await this.useJwt().sharedService.fetchFaqs(query);
        const { data, pagination } = request.data;
        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;

        this.faqs = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },

  }
}
</script>

<style lang="scss">
  @import '@core/scss/base/pages/page-faq.scss';
  @import '@core/scss/base/pages/page-public.scss';
</style>
